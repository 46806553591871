// Libraries
import React from 'react';
import {useNavigate} from 'react-router-dom';

// MUI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

// App
import Space from './Space';
import useAuth from './hooks/useAuth';
import {removeBearerToken} from './utils/auth';

const Navbar = () => {
  const isAuthenticated = useAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <AppBar
      position='static'
      color='primary'
      sx={{
        boxShadow: 'none',
      }}
    >
      <Container maxWidth='xl'>
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <Button onClick={() => navigate('/')}>
              <Typography variant='h2' component='div' sx={{flexGrow: 1}}>
                🏃‍♂️
              </Typography>
            </Button>
          </Container>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          >
            {!isAuthenticated ? (
              <React.Fragment>
                <Button
                  size={isMobile ? 'small' : 'large'}
                  variant='contained'
                  color='secondary'
                  onClick={() => navigate('/sign-up')}
                >
                  Sign up
                </Button>
                <Space width={12} />
                <Button
                  size={isMobile ? 'small' : 'large'}
                  variant='outlined'
                  color='secondary'
                  onClick={() => navigate('/login')}
                >
                  Log in
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  size={isMobile ? 'small' : 'large'}
                  variant='contained'
                  color='secondary'
                  onClick={() => navigate('/distance-fixer')}
                >
                  Distance Fixer
                </Button>
                <Space width={12} />
                <Button
                  size={isMobile ? 'small' : 'large'}
                  variant='outlined'
                  color='secondary'
                  onClick={() => {
                    removeBearerToken();
                    navigate('/');
                  }}
                >
                  Logout
                </Button>
              </React.Fragment>
            )}
          </Container>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
