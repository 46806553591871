import React from 'react';

// AppRouter.js
import {Routes, Route} from 'react-router-dom';
import HomePage from './HomePage';
import SignUpPage from './SignUpPage';
import LoginPage from './LoginPage';
import DistanceAdjusterPage from './DistanceAdjusterPage';
import RedirectIfAuthenticated from './RedirectIfAuthenticated';
import StravaAccountConnectSuccessPage from './StravaAccountConnectSuccessPage';

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <RedirectIfAuthenticated>
            <HomePage />
          </RedirectIfAuthenticated>
        }
        index
      />
      <Route
        path='/sign-up'
        element={
          <RedirectIfAuthenticated>
            <SignUpPage />
          </RedirectIfAuthenticated>
        }
      />
      <Route
        path='/login'
        element={
          <RedirectIfAuthenticated>
            <LoginPage />
          </RedirectIfAuthenticated>
        }
      />
      <Route path='/connect-success' element={<StravaAccountConnectSuccessPage />} />
      <Route path='/distance-fixer' element={<DistanceAdjusterPage />} />
      <Route path='*' element={<h1>404 - Page not found</h1>} />
    </Routes>
  );
};

export default AppRouter;
