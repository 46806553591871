// Libraries
import React from 'react';
import {useNavigate} from 'react-router-dom';

// MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Space from './Space';

export const HomePage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          paddingLeft: {xs: '16px', sm: '200px'},
          paddingRight: {xs: '16px', sm: '200px'},
          maxWidth: '700px',
        }}
      >
        <Typography variant='h2' component='div' sx={{flexGrow: 1}}>
          Adjust your treadmill runs
        </Typography>
        <Space height={12} />
        <Typography variant='h6' component='div' sx={{flexGrow: 1}}>
          Strava treadmill fixer allows you to adjust the distance of your treadmill runs without
          losing important data like cadence, power, and heart rate
        </Typography>
        <Space height={12} />
        <Button variant='contained' onClick={() => navigate('/sign-up')}>
          Sign up
        </Button>
      </Box>
    </Box>
  );
};

export default HomePage;
