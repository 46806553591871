// Libraries
import {useState, useEffect} from 'react';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const updateAuthStatus = () => {
      const token = localStorage.getItem('bearerToken');
      if (token) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    updateAuthStatus(); // Call once on initial render
    window.addEventListener('onAuthChange', updateAuthStatus); // Update when the custom event is triggered

    return () => {
      window.removeEventListener('onAuthChange', updateAuthStatus); // Cleanup the listener when the component is unmounted
    };
  }, []);

  return isAuthenticated;
};

export default useAuth;
