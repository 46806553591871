// Libraries
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

// MUI
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

// App
import config from './config.js';

const StravaAccountConnectSuccessPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRefreshToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      try {
        const response = await axios.post(`${config.apiRoute}/set-refresh-token`, {
          userId: localStorage.getItem('userId'),
          code: code,
        });

        if (response.data.message) {
          setError(response.data.message);
          return;
        }

        response.data.user.stravaRefreshToken &&
          localStorage.setItem('stravaRefreshToken', response.data.user.stravaRefreshToken);
        navigate('/distance-fixer');
      } catch (error) {
        // Handle the error, e.g. update the error state
        setError('An error occurred while fetching the refresh token');
      }
    };

    // Call the async function
    fetchRefreshToken();
  }, [navigate]);
  return (
    <>
      {error ? (
        <Alert severity='error'>{error}</Alert>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default StravaAccountConnectSuccessPage;
