// Libraries
import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

// MUI
import {styled} from '@mui/system';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// App
import config from './config.js';
import {setBearerToken} from './utils/auth';

const Form = styled('form')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({theme}) => ({
  marginBottom: theme.spacing(2),
}));

const SubmitButton = styled(Button)(({theme}) => ({
  marginTop: theme.spacing(2),
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
});

const Signup = () => {
  const [formAlert, setFormAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axios.post(`${config.apiRoute}/signup`, {
          firstName: '', // Add the firstName field to your form if needed
          lastName: '', // Add the lastName field to your form if needed
          email: values.email,
          password: values.password,
        });

        if (response.data.message) {
          return setFormAlert(response.data.message);
        }

        const {token} = response.data;
        setBearerToken(token);
        localStorage.setItem('userId', response.data.user.id);
        navigate('/');

        // Save the token in localStorage or a cookie and redirect the user to the desired page
      } catch (error) {
        console.error('Error during signup:', error.response.data);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Container maxWidth='xs'>
      <Typography variant='h4' align='center' gutterBottom>
        Sign up
      </Typography>
      {formAlert && <Alert severity='error'>{formAlert}</Alert>}
      <Form onSubmit={formik.handleSubmit}>
        <StyledTextField
          label='Email'
          variant='outlined'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <StyledTextField
          label='Password'
          variant='outlined'
          type='password'
          name='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <StyledTextField
          label='Confirm Password'
          variant='outlined'
          type='password'
          name='confirmPassword'
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
        />
        <SubmitButton variant='contained' color='primary' type='submit' disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Sign up'}
        </SubmitButton>
      </Form>
    </Container>
  );
};

export default Signup;
