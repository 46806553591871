// Libraries
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useFormik} from 'formik';
import * as Yup from 'yup';

// MUI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

// App
import Space from './Space';
import config from './config.js';

const GarminDownloadInstructionsDialog = ({handleClose, open}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>How to download your Garmin data</DialogTitle>
      <DialogContent>
        <DialogContentText>
          1. Log into your Garmin account at{' '}
          <Link
            href='https://sso.garmin.com/portal/sso/en-US/sign-in?clientId=GarminConnect&service=https://connect.garmin.com/modern/activities'
            target='_blank'
          >
            https://connect.garmin.com/signin
          </Link>
        </DialogContentText>
        <DialogContentText>2. Select the activity you'd like to adjust</DialogContentText>
        <DialogContentText>
          3. Click the gear icon in the top right corner of the page
        </DialogContentText>
        <DialogContentText>
          4. Click "Export to TCX" and save the file to your computer
        </DialogContentText>
        <DialogContentText>5. Upload the file to this page</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const MobileWarningModal = ({handleClose, open}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          This site is not optimized for mobile. Please use a desktop browser to upload your file.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>I Understand</Button>
      </DialogActions>
    </Dialog>
  );
};

const validationSchema = Yup.object().shape({
  file: Yup.mixed().required('Please select a file'),
  distance: Yup.number().required('Pleaase enter a distance').positive('Distance must be positive'),
});

const DistanceAdjusterPage = () => {
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [duplicate, setDuplicate] = useState(null);
  const stravaRefreshToken = localStorage.getItem('stravaRefreshToken');
  const userId = localStorage.getItem('userId');
  const [open, setOpen] = React.useState(false);
  const [unit, setUnit] = useState('MILES');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Mobile Warning Modal
  const isMobile = useMediaQuery('(max-width:600px)');
  const [openMobileWarning, setOpenMobileWarning] = React.useState(false);
  const handleOpenMobileWarning = () => setOpenMobileWarning(true);
  const handleCloseMobileWarning = () => setOpenMobileWarning(false);

  useEffect(() => {
    if (isMobile) {
      handleOpenMobileWarning();
    }
  }, [isMobile]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    setError(false);
    setSuccess(false);
    formik.setFieldValue('file', file);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      file: null,
      distance: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError(false);
      setSuccess(false);
      const distanceInMiles = unit === 'MILES' ? values.distance : values.distance * 0.621371;
      const formData = new FormData();
      formData.append('file', values.file);
      formData.append('distance', distanceInMiles);
      formData.append('stravaRefreshToken', stravaRefreshToken);
      formData.append('userId', userId);

      try {
        const response = await axios.post(`${config.apiRoute}/upload`, formData);
        setSuccess(true);
        setDuplicate(response.data.data.activity_id);
      } catch (error) {
        const errorMessage = error.response.data.data.error;
        const words = errorMessage.split(' ');
        const activityId = words[words.length - 1];
        setError(true);
        setDuplicate(activityId);
      }
    },
  });

  return (
    <Box
      component='div'
      className='App'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        marginLeft: '10px',
        marginRight: '10px',
      }}
    >
      {stravaRefreshToken && stravaRefreshToken !== undefined && stravaRefreshToken !== null ? (
        <React.Fragment>
          <Typography variant='h3'>Garmin ➡️ Strava Treadmill Distance Adjuster</Typography>
          <Space height={12} />
          <Box
            component='form'
            onSubmit={(event) => {
              event.preventDefault();
              formik.handleSubmit(event);
            }}
            sx={{display: 'flex', flexDirection: 'column', gap: 1, width: {xs: '100%', sm: '40%'}}}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <input
                type='file'
                id='file'
                name='file'
                accept='.tcx'
                hidden
                onChange={handleFileChange}
              />
              <label htmlFor='file'>
                <Button variant='contained' component='span'>
                  Select TCX File
                </Button>
              </label>
              <Space width={12} />
              {fileName ? (
                <Typography variant='paragraph'>Selected file: {fileName}</Typography>
              ) : (
                <Link style={{cursor: 'pointer'}} onClick={handleOpen}>
                  How to download a TCX file from Garmin
                </Link>
              )}
            </div>
            {formik.touched.file && formik.errors.file && (
              <Typography variant='paragraph' color='error'>
                {formik.errors.file}
              </Typography>
            )}
            <Space height={12} />
            <Box sx={{display: 'flex'}}>
              <Box sx={{flex: 1.5}}>
                <TextField
                  label='Enter actual distance'
                  type='number'
                  id='distance'
                  name='distance'
                  value={formik.values.distance}
                  onChange={formik.handleChange}
                  error={formik.touched.distance && Boolean(formik.errors.distance)}
                  helperText={formik.touched.distance && formik.errors.distance}
                  fullWidth
                />
              </Box>
              <Space width={12} />
              <Box sx={{flex: 1}}>
                <FormControl fullWidth>
                  <InputLabel style={{backgroundColor: '#f5f5f5'}} id='unit-label'>
                    Unit
                  </InputLabel>
                  <Select labelId='unit-label' id='unit' value={unit} onChange={handleUnitChange}>
                    <MenuItem value='MILES'>Miles</MenuItem>
                    <MenuItem value='KILIMETERS'>Kilometers</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Space height={12} />
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? <CircularProgress size={24} /> : 'Upload to strava'}
            </Button>
            <Space height={12} />
            {error && (
              <Typography color='error'>
                ⚠️ The activity you are uploading is a duplicate. Please{' '}
                <Link href={`https://www.strava.com/activities/${duplicate}`} target='_blank'>
                  delete this activity
                </Link>{' '}
                from Strava and try again.
              </Typography>
            )}
            {success && (
              <React.Fragment>
                <Typography>
                  ✅ Activity uploaded successfully!{' '}
                  <Link href={`https://www.strava.com/activities/${duplicate}`} target='_blank'>
                    Click here to view.
                  </Link>{' '}
                </Typography>
                <Space height={4} />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography>☕️ Enjoying the tool?</Typography>
                  <Space width={8} />
                  <a
                    href='https://www.buymeacoffee.com/treadmillfixer'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      alt='Buy Me A Coffee'
                      style={{width: '150px', height: 'auto'}}
                      src='https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=treadmillfixer&button_colour=FFDD00&font_colour=000000&font_family=Arial&outline_colour=000000&coffee_colour=ffffff'
                    />
                  </a>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </React.Fragment>
      ) : (
        <Button
          variant='contained'
          color='primary'
          onClick={() =>
            window.open(
              'https://www.strava.com/oauth/authorize?client_id=106005&response_type=code&redirect_uri=https://www.stravatreadmillfixer.com/connect-success&scope=read,activity:write',
              '_blank',
            )
          }
        >
          Connect Strava Account
        </Button>
      )}
      <GarminDownloadInstructionsDialog
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
      />
      <MobileWarningModal
        handleClose={handleCloseMobileWarning}
        handleOpen={handleOpenMobileWarning}
        open={openMobileWarning}
      />
    </Box>
  );
};

export default DistanceAdjusterPage;
