// Libraries
import React from 'react';
import ReactGA from 'react-ga4';

// MUI
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {BrowserRouter as Router} from 'react-router-dom';

// App
import Navbar from './Navbar';
import Space from './Space';
import AppRouter from './AppRouter';

ReactGA.initialize('G-MS9PY5D8SV');

const theme = createTheme({
  palette: {
    primary: {
      main: '#541554',
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

const AppWrapper = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Space height={40} />
        <AppRouter />
      </ThemeProvider>
    </Router>
  );
};

export default AppWrapper;
