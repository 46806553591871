// components/RedirectIfAuthenticated.js
import {useEffect} from 'react';
import useAuth from './hooks/useAuth';
import {useNavigate} from 'react-router-dom';

const RedirectIfAuthenticated = ({children}) => {
  const isAuthenticated = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/distance-fixer');
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? null : children;
};

export default RedirectIfAuthenticated;
